<template>
  <v-container fluid class="py-0">
    <div
      class="
        secondary
        px-4 px-sm-8
        br-8
        py-4
        d-flex
        flex-column flex-md-row
        align-center align-stretch align-md-center
      "
    >
      <div class="my-4 my-md-0 mr-0 mr-md-4">
        <p class="mb-0 text-h4 font-weight-bold">
          Current Rating {{ reviews_average }}
        </p>
        <div class="d-flex align-center">
          <v-rating
            dense
            background-color="primary"
            empty-icon="mdi-star-outline"
            full-icon="mdi-star"
            half-icon="mdi-star-half-full"
            half-increments
            length="5"
            readonly
            size="18"
            :value="reviews_average"
          />
          <span class="caption">({{ reviews_count }}) </span>
        </div>
      </div>
      <v-text-field
        v-model="name"
        hide-details
        dense
        class="mx-4 my-4 my-md-0"
        label="Search by Customer Name"
        append-icon="mdi-magnify"
        clearable
      >
        <!-- <template v-slot:[`append`]>
          <v-icon>mdi-magnify</v-icon>
        </template> -->
      </v-text-field>
      <v-select
        v-model="rating"
        hide-details
        class="mx-4 my-4 my-md-0"
        dense
        label="Filter by Star Rating"
        :items="[1, 2, 3, 4, 5]"
        clearable
      >
        <template slot="selection" slot-scope="data">
          <div class="d-flex align-center">
            <v-rating
              dense
              background-color="primary"
              full-icon="mdi-star"
              :length="data.item"
              readonly
              size="18"
              :value="data.item"
            />
            <span v-if="![1, 5].includes(data.item)">& up</span>
          </div>
        </template>
        <template slot="item" slot-scope="data">
          <div class="d-flex align-center">
            <v-rating
              dense
              background-color="primary"
              full-icon="mdi-star"
              :length="data.item"
              readonly
              size="18"
              :value="data.item"
            />
            <span v-if="![1, 5].includes(data.item)">& up</span>
          </div>
        </template>
      </v-select>

      <component
        :is="$vuetify.breakpoint.xsOnly ? 'v-dialog' : 'v-menu'"
        ref="date_filter_menu"
        v-model="date_filter_menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="display_date"
            label="Filter by Date"
            class="mx-4 my-4 my-md-0"
            readonly
            hide-details
            dense
            v-bind="attrs"
            v-on="on"
            clearable
            @click:clear="
              date = [];
              $refs.date_filter_menu.save([]);
            "
          >
          </v-text-field>
        </template>
        <v-date-picker
          class="mb-0"
          v-model="selected_date"
          scrollable
          range
          :full-width="$vuetify.breakpoint.xsOnly"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="date_filter_menu = false">
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.date_filter_menu.save(selected_date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </component>

      <v-btn
        text
        color="primary"
        class="ml-2 text-h5"
        :disabled="!are_filters_set"
        @click="clear_filters()"
      >
        Clear filters
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import { VMenu, VDialog } from "vuetify/lib";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "ReviewsFilters",
  components: {
    VMenu,
    VDialog,
  },
  data() {
    return {
      date_filter_menu: "",
      selected_date: [],
      name_timer: null,
    };
  },
  computed: {
    ...mapGetters("ShopStore", ["table_booking"]),
    ...mapState("ReviewStore", ["filters"]),
    name: {
      get() {
        return this.filters.customer_name;
      },
      set(v) {
        clearTimeout(this.name_timer);
        this.name_timer = setTimeout(() => {
          this.set_filters({
            customer_name: v,
          });
          this.get_reviews(true);
        }, 500);
      },
    },
    setted_date: {
      get() {
        return [
          ...(this.filters.timestamp_gte
            ? [moment(this.filters.timestamp_gte).format("YYYY-MM-DD")]
            : []),
          ...(this.filters.timestamp_lte
            ? [moment(this.filters.timestamp_gte).format("YYYY-MM-DD")]
            : []),
        ];
      },
      set(v) {
        this.selected_date = v;
      },
    },
    date: {
      get() {
        return this.setted_date;
      },
      set(v) {
        this.setted_date = v;
        const [date_gte, date_lte] = v;
        if (date_gte) {
          if (date_lte) {
            this.set_filters({
              timestamp_gte: moment.utc(date_gte).toISOString(),
              timestamp_lt: moment.utc(date_lte).add(1, "days").toISOString(),
            });
          } else {
            this.set_filters({
              timestamp_gte: moment.utc(date_gte).toISOString(),
              timestamp_lt: moment.utc(date_gte).add(1, "days").toISOString(),
            });
          }
        } else {
          this.set_filters({
            timestamp_gte: null,
            timestamp_lte: null,
          });
        }
        this.get_reviews(true);
      },
    },
    rating: {
      get() {
        return this.filters.rating_gte || this.filters.rating_lt;
      },
      set(v) {
        if (v == 1) {
          this.set_filters({
            rating_gte: null,
            rating_lt: 2,
          });
        } else if (v > 1) {
          this.set_filters({
            rating_gte: v,
            rating_lt: null,
          });
        } else {
          this.set_filters({
            rating_gte: null,
            rating_lt: null,
          });
        }
        this.get_reviews(true);
      },
    },
    display_date() {
      const [greater, less] = this.selected_date;
      if (greater) {
        if (less) {
          return `${moment(greater).format("DD/MM/YYYY")} - ${moment(
            less
          ).format("DD/MM/YYYY")}`;
        }
        return moment(greater).format("DD/MM/YYYY");
      }
      return this.selected_date;
    },
    are_filters_set() {
      return (
        !!this.filters.rating_gte ||
        !!this.filters.timestamp_gte ||
        !!this.filters.customer_name
      );
    },
    reviews_count() {
      return this.table_booking.reviews_count || 0;
    },
    reviews_average() {
      return this.table_booking.reviews_average || "N/A";
    },
  },
  methods: {
    ...mapActions("ReviewStore", ["set_filters", "get_reviews"]),
    set_rating(v) {
      const [gte, lt] = v;
      this.set_filters({
        rating_lt: lt + 1,
        rating_gte: gte,
      });
      this.get_reviews();
    },
    clear_filters() {
      this.set_filters({
        customer_name: null,
        timestamp_gte: null,
        timestamp_lte: null,
        rating_gte: null,
        rating_lte: null,
      });
      this.date = [];
      this.get_reviews();
    },
  },
};
</script>

<style></style>
