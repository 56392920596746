<template>
  <v-container fluid class="position-relative">
    <v-overlay color="white" absolute :value="is_loading_reviews"> </v-overlay>
    <CursorPaginatedDatatable
      class="pa-2"
      :headers="headers"
      :items="reviews"
      :filters="filters"
      :total-results="total_results"
      :sort-by="sort_by"
      :next-page="next_page"
      :prev-page="prev_page"
      :next-page-sorting="next_page_sorting"
      :prev-page-sorting="prev_page_sorting"
      :datatable-options="datatable_options"
      :update-options="set_datatable_options"
      :get-items="get_reviews"
      :add-filter="set_filters"
      :reset-sorted-datatable="reset_datatable_with_sorting"
      :loading="is_loading_reviews"
      :no-data-text="`No reviews match your search`"
      :datatable-class="`br-8`"
      @update-sort-by="(v) => (sort_by = v)"
    >
      <template v-slot:[`item.rating`]="{ item }">
        <v-rating
          :value="item.rating"
          dense
          readonly
          :background-color="item.is_active ? 'primary' : 'grey'"
          :color="item.is_active ? 'primary' : 'grey'"
          half-icon="mdi-star-half-full"
          half-increments
        />
      </template>
      <template v-slot:[`item.timestamp`]="{ item }">
        {{ moment(item.timestamp).format("DD/MM/YYYY HH:mm") }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-switch
          v-model="item.is_active"
          readonly
          :disabled="!item.is_active"
          @click="toggle_hide_modal(item.id)"
        />
        <v-overlay v-if="!item.is_active" color="white" absolute />
      </template>
      <template v-slot:[`item.reply`]="{ item }">
        <v-btn icon large @click="toggle_edit_review(item)">
          <v-icon large>mdi-reply</v-icon>
        </v-btn>
      </template>
    </CursorPaginatedDatatable>

    <AdminDialog
      v-if="edited_review"
      header="Respond to Review"
      confirm_text="Save"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :show_dialog.sync="edited_review_dialog"
      @confirm="save_reply"
    >
      <template v-slot:[`content`]>
        <p class="font-weight-bold mb-1">
          {{ edited_review.user.name }}
        </p>
        <div class="d-flex align-center">
          <v-rating
            dense
            background-color="primary"
            empty-icon="mdi-star-outline"
            full-icon="mdi-star"
            half-icon="mdi-star-half-full"
            half-increments
            length="5"
            readonly
            size="20"
            :value="edited_review.rating"
          />
          <span class="text--secondary ml-4">
            {{ moment(edited_review.timestamp).fromNow() }}
          </span>
        </div>
        <p>{{ edited_review.text }}</p>
        <p class="font-weight-bold">Your Response</p>
        <v-textarea v-model="reply_text" auto-grow rows="1" />
      </template>
    </AdminDialog>

    <AdminDialog
      confirm_color="red"
      header="Hide review"
      confirm_text="Hide"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :show_dialog.sync="delete_review_dialog"
      @close="toggle_hide_modal()"
      @confirm="
        delete_review(delete_review_id);
        toggle_hide_modal();
      "
    >
      <template v-slot:[`content`]>
        <v-alert type="warning" border="left" colored-border>
          This action is irreversible. <br />
          Use with caution - It's safe to delete a nsfw/rude comment, but
          removing low rating review may not go overlooked, causing reputation
          issues.
        </v-alert>
      </template>
    </AdminDialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";

import CursorPaginatedDatatable from "@/components/CursorPaginatedDatatable.vue";
import AdminDialog from "@/components/base/AdminDialog";

export default {
  name: "ReviewsDatatable",
  components: {
    AdminDialog,
    CursorPaginatedDatatable,
  },
  data() {
    return {
      moment,
      edited_review: null,
      reply_text: null,
      edited_review_dialog: false,
      delete_review_dialog: false,
      delete_review_id: null,
      sort_by: "timestamp",
    };
  },
  computed: {
    ...mapState("ShopStore", ["shop"]),
    ...mapState("ReviewStore", [
      "filters",
      "reviews",
      "prev_page",
      "next_page",
      "prev_page_sorting",
      "next_page_sorting",
      "datatable_options",
      "total_results",
      "is_loading_reviews",
    ]),
    headers() {
      const total_ratings = this.total_results ? `(${this.total_results})` : "";
      return [
        {
          text: `Ratings ${total_ratings}`,
          value: "rating",
          width: "140px",
        },
        {
          text: "Date Created",
          value: "timestamp",
        },
        {
          text: "Review Comment",
          value: "text",
          width: "20%",
          sortable: false,
          cellClass: "review-cell",
          class: "review-cell",
        },
        {
          text: "Customer Name",
          value: "user.name",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        {
          text: "Reply",
          value: "reply",
          align: "center",
          sortable: false,
        },
        {
          text: "Your Response",
          value: "reply_text",
          width: "20%",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions("ReviewStore", [
      "get_reviews",
      "set_datatable_options",
      "set_filters",
      "update_review",
      "delete_review",
      "reset_datatable_with_sorting",
    ]),
    toggle_edit_review(review) {
      if (this.edited_review?.id == review.id) {
        (this.edited_review_dialog = false), (this.edited_review = null);
        this.reply_text = null;
      } else {
        this.edited_review = review;
        this.reply_text = review.reply_text;
        this.edited_review_dialog = true;
      }
    },
    toggle_hide_modal(review_id = null) {
      if (review_id) {
        this.delete_review_dialog = true;
        this.delete_review_id = review_id;
      } else {
        this.delete_review_dialog = false;
        this.delete_review_id = null;
      }
    },
    save_reply() {
      this.update_review({
        review_id: this.edited_review.id,
        reply_text: this.reply_text,
      });
      this.edited_review_dialog = false;
      this.edited_review = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.review-cell {
  height: auto;
}
::v-deep tr {
  position: relative;
  td.v-data-table__mobile-row {
    height: auto !important;
  }
}
.reply-box {
  border-left: 3px solid rgba(0, 0, 0, 0.15);
}
</style>
