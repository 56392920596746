<template>
  <div>
    <ReviewsFilters />
    <ReviewsDatatable />
  </div>
</template>

<script>
import ReviewsFilters from "./components/ReviewsFilters";
import ReviewsDatatable from "./components/ReviewsDatatable";

export default {
  name: "BookingReviews",
  components: {
    ReviewsFilters,
    ReviewsDatatable,
  },
};
</script>

<style></style>
