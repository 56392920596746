var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"position-relative",attrs:{"fluid":""}},[_c('v-overlay',{attrs:{"color":"white","absolute":"","value":_vm.is_loading_reviews}}),_c('CursorPaginatedDatatable',{staticClass:"pa-2",attrs:{"headers":_vm.headers,"items":_vm.reviews,"filters":_vm.filters,"total-results":_vm.total_results,"sort-by":_vm.sort_by,"next-page":_vm.next_page,"prev-page":_vm.prev_page,"next-page-sorting":_vm.next_page_sorting,"prev-page-sorting":_vm.prev_page_sorting,"datatable-options":_vm.datatable_options,"update-options":_vm.set_datatable_options,"get-items":_vm.get_reviews,"add-filter":_vm.set_filters,"reset-sorted-datatable":_vm.reset_datatable_with_sorting,"loading":_vm.is_loading_reviews,"no-data-text":"No reviews match your search","datatable-class":"br-8"},on:{"update-sort-by":function (v) { return (_vm.sort_by = v); }},scopedSlots:_vm._u([{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{attrs:{"value":item.rating,"dense":"","readonly":"","background-color":item.is_active ? 'primary' : 'grey',"color":item.is_active ? 'primary' : 'grey',"half-icon":"mdi-star-half-full","half-increments":""}})]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.timestamp).format("DD/MM/YYYY HH:mm"))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"readonly":"","disabled":!item.is_active},on:{"click":function($event){return _vm.toggle_hide_modal(item.id)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}}),(!item.is_active)?_c('v-overlay',{attrs:{"color":"white","absolute":""}}):_vm._e()]}},{key:"item.reply",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.toggle_edit_review(item)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-reply")])],1)]}}],null,true)}),(_vm.edited_review)?_c('AdminDialog',{attrs:{"header":"Respond to Review","confirm_text":"Save","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"show_dialog":_vm.edited_review_dialog},on:{"update:show_dialog":function($event){_vm.edited_review_dialog=$event},"confirm":_vm.save_reply},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"font-weight-bold mb-1"},[_vm._v(" "+_vm._s(_vm.edited_review.user.name)+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-rating',{attrs:{"dense":"","background-color":"primary","empty-icon":"mdi-star-outline","full-icon":"mdi-star","half-icon":"mdi-star-half-full","half-increments":"","length":"5","readonly":"","size":"20","value":_vm.edited_review.rating}}),_c('span',{staticClass:"text--secondary ml-4"},[_vm._v(" "+_vm._s(_vm.moment(_vm.edited_review.timestamp).fromNow())+" ")])],1),_c('p',[_vm._v(_vm._s(_vm.edited_review.text))]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Your Response")]),_c('v-textarea',{attrs:{"auto-grow":"","rows":"1"},model:{value:(_vm.reply_text),callback:function ($$v) {_vm.reply_text=$$v},expression:"reply_text"}})]},proxy:true}],null,true)}):_vm._e(),_c('AdminDialog',{attrs:{"confirm_color":"red","header":"Hide review","confirm_text":"Hide","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"show_dialog":_vm.delete_review_dialog},on:{"update:show_dialog":function($event){_vm.delete_review_dialog=$event},"close":function($event){return _vm.toggle_hide_modal()},"confirm":function($event){_vm.delete_review(_vm.delete_review_id);
      _vm.toggle_hide_modal();}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-alert',{attrs:{"type":"warning","border":"left","colored-border":""}},[_vm._v(" This action is irreversible. "),_c('br'),_vm._v(" Use with caution - It's safe to delete a nsfw/rude comment, but removing low rating review may not go overlooked, causing reputation issues. ")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }